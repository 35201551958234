import React from 'react';
import logo from "./asset/logo.png";
import icon_facebook from "../Assets/icons/icon_facebook.png";
import icon_insta from "../Assets/icons/icon_insta.png";
import icon_yt from "../Assets/icons/icon_yt.png";
import icon_linkedin from "../Assets/icons/icon_linkedin.png";

function Footer() {
  return (
    <footer id="contact" className="bg-[#f1f7f2] mx-auto mt-8">
      <div className="flex flex-col justify-center items-center">

        {/* Social Media Icons */}
        <div className=' w-full flex p-2 bg-green-50 justify-center items-center mb-5'>
          <div className="flex gap-3 border-b-2 w-[80%] justify-center items-center py-3 mb-5">
          <a href="https://www.facebook.com/IndusValleyMukteshwar/" target="_blank" rel="noopener noreferrer">
            <img className="w-8 sm:w-12 md:p-1 md:rounded-lg animate__zoomIn2" src={icon_facebook} alt="Facebook Link.." />
          </a>
          <a href="https://www.instagram.com/indusvalley.mukteshwar/" target="_blank" rel="noopener noreferrer">
            <img className="w-8 sm:w-12 md:p-1 md:rounded-lg animate__zoomIn2" src={icon_insta} alt="Insta Link.." />
          </a>
          <a href="https://www.youtube.com/@indusvalleymukteshwar" target="_blank" rel="noopener noreferrer">
            <img className="w-8 sm:w-12 md:p-1 md:rounded-lg animate__zoomIn2" src={icon_yt} alt="Youtube Link.." />
          </a>
          <a href="https://www.linkedin.com/company/the-shubham-group1/" target="_blank" rel="noopener noreferrer">
            <img className="w-8 sm:w-12 md:p-1 md:rounded-lg animate__zoomIn2" src={icon_linkedin} alt="LinkedIn Link.." />
          </a>
        </div>
        </div>

        {/* Footer Grid */}
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-8">

          {/* Logo Section */}
          <section className="flex text-center md:text-left flex-col gap-5 justify-center items-center">
            <a href="/" rel="noreferrer">
              <img className="w-[200px] mx-auto rounded-lg" src={logo} alt="indus valley mukteshwar logo" />
            </a>
          </section>

          {/* Registered Office Section */}
          <div className="flex flex-col gap-3 items-center md:items-start">
            <section className="flex text-center md:text-left flex-col gap-2">
              <p className="text-black text-2xl md:text-xl font-semibold">Registered Office</p>
              <p className="text-zinc-700 text-base font-times font-thin hover:underline">
                The Shubham Group, <br /> A-24, Okhla Industrial Area, Phase-2, <br /> Delhi, Pincode - 110020
              </p>
            </section>

            {/* Corporate Office Section */}
            <section className="flex text-center md:text-left flex-col gap-2">
              <p className="text-black text-2xl md:text-xl font-semibold">Corporate Office</p>
              <p className="text-zinc-700 text-base font-times font-thin hover:underline">
                315, Block-B, <br /> Sector-105 Noida, <br /> Uttar Pradesh, Pincode - 201304
              </p>
            </section>
          </div>

          {/* Site Address and Contact Us */}
          <div className="flex flex-col gap-3 items-center md:items-start">
            <section className="flex text-center md:text-left flex-col gap-2">
              <p className="text-black text-2xl md:text-xl font-semibold">Site Address</p>
              <p className="text-zinc-700 text-base font-times font-thin hover:underline">
                Managher, Near Dhanachuli Bend, <br /> Sunderkhal, Nainital, <br /> Uttarakhand - 263132
              </p>
            </section>

            <section className="flex flex-col text-center md:text-left gap-2">
              <p className="text-black text-2xl md:text-xl font-semibold">Contact Us</p>
              <div className="flex flex-col items-center md:items-start">
                <a className="text-zinc-700 text-base hover:text-green-500" href="mailto:info@theshubhamgroup.com">info@theshubhamgroup.com</a>
                <a className="text-zinc-700 text-base hover:text-green-500" href="tel:8510850101">85 10 85 01 01</a>
              </div>
            </section>
          </div>

          {/* About Us Section */}
          <div className="flex flex-col text-center md:text-left gap-3 items-center md:items-start">
            <h2 className="text-xl text-center font-semibold text-gray-800 mb-2"><a href="https://theshubhamgroup.com">Know More About <p className='text-center'>Developer</p>
            <p className='text-gray-600 text-md yeseva text-center mt-2'> The Shubham Group </p></a></h2>

          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <section className="copy-right mt-8">
        <p className="text-zinc-700 text-center text-sm font-times p-3 pt-0">Copyright © 2024 Indus Valley Mukteshwar. All Rights Reserved</p>
      </section>
    </footer>
  );
}

export default Footer;
