import React, { useState, useEffect } from 'react';
import { ChevronLeft, Armchair, ChevronRight, Mountain, Menu, X, Users, Home, Map, } from 'lucide-react';
import Footer from "../newComponents/Footer"
import bottomline from "./asset/bottomline.png"
import axios from 'axios';
import { motion } from 'framer-motion';

function Form({ setShowPopup, position, name, phone, handleSubmit, setName, setPhone }) {
  return (
    <div className={`${position ? position : "fixed"} inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50`}>
      <div className="bg-green-50 relative bg-opacity-50 backdrop-blur-lg p-8 rounded-2xl shadow-2xl max-w-md w-full mx-4 transform transition-all">
        <h4 className="text-2xl font-bold mb-4">Welcome to Indus Valley!</h4>
        <div onClick={() => { setShowPopup(false) }} className='p-3 absolute top-5 flex justify-center items-center cursor-pointer right-5 h-[40px] w-[40px] bg-white rounded-full'>
          <X size={20} className='text-black font-bold' />
        </div>
        <p className="text-gray-50 mb-6">
          Book a site visit today and experience the luxury of mountain living.
          Special launch prices available for limited time.
        </p>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-4 py-3 bg-transparent text-center rounded-lg placeholder-white border-2 border-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="tel"
            placeholder="Enter your phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            maxLength="10"
            className="w-full px-4 py-3 bg-transparent text-center rounded-lg placeholder-white border-2 border-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="flex space-x-4">
            <button onClick={
              (e) => {
                handleSubmit(e);
              }

            } className="flex-1 px-6 py-3 bg-green-700 rounded-lg hover:opacity-90">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

let a = 0

const CreativeLandingPage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [floorPlan, setFloorPlan] = useState("Studios");
  const [animate, setAnimate] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !phone) {
      alert('Please fill in all fields');
      return;
    }

    const payload = { name: name, phone: phone };
    console.log(payload);

    try {
      const response = await axios.post(
        'https://account.solidperformers.com/leadsapi/data/66323ef63df28f079d6141787a291ca9',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        alert('Data submitted successfully!');
        setShowPopup(false);
        setName('');
        setPhone('');
      } else {
        alert('Failed to submit data');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('An error occurred while submitting data');
    }
  };


  const features = [
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/cottage.png", title: "Independent cottages", description: "Simplex and duplex options available" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/customizecottage.png", title: "Customized cottages", description: "Tailored-made as per customer design" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/residential.png", title: "Apartments", description: "1-2 BHK and Studio apartments" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/security-agent.png", title: "Security", description: "CCTV surveillance with 24/7 security guards" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/amenities.png", title: "Amenities", description: "Hillcrest Cafe, Clubhouse, and Trekking trails" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/infrastructure.png", title: "Infrastructure", description: "Private project road and streetlights" }
  ];

  const uniquePoints = [
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/gated.png", text: "Secured gated community" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/sealevel.png", text: "At the altitude of 8000 feet from sea level" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/360.png", text: "360-degree panoramic views of Nanda Devi Glaciers" },
    { icon: "https://cdn-icons-png.flaticon.com/512/484/484141.png", text: "Private road access to every property" },
    { icon: "https://cdn-icons-png.flaticon.com/512/13338/13338981.png", text: "Elite crowd and premium amenities" },
    { icon: "https://cdn-icons-png.flaticon.com/512/2140/2140147.png", text: "Snowfall Region" }
  ];

  useEffect(() => {
    let timer;

    if (!showPopup) {
      timer = setTimeout(() => {
        setAnimate(true);
      }, 500); // Delay for animation
    }

    return () => clearTimeout(timer);
  }, [showPopup]);


  const carouselSlides = [
    {
      image: "https://d2ehq5aws28ia0.cloudfront.net/snow/4.jpg",
    },
    {
      image: "https://d2ehq5aws28ia0.cloudfront.net/project/2.jpg",
    },
    {
      image: "https://d2ehq5aws28ia0.cloudfront.net/drone/6.jpg",
    },
    {
      image: "https://d2ehq5aws28ia0.cloudfront.net/interior/19.jpg",
    },
  ];

  useEffect(() => {

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
      const scrolled = window.scrollY;
      const maxHeight = document.documentElement.scrollHeight;
      const progress = (scrolled / maxHeight) * 100;
      setScrollProgress(progress);
      if (a < 1) {
        if (scrolled) {
          setShowPopup(true);
          a++
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % carouselSlides.length);
    }, 2500);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(interval);
    };
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % carouselSlides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + carouselSlides.length) % carouselSlides.length);
  };


  const [allImages] = useState([
    { image: "https://d2ehq5aws28ia0.cloudfront.net/snow/4.jpg" },
    { image: "https://d2ehq5aws28ia0.cloudfront.net/project/2.jpg" },
    { image: "https://d2ehq5aws28ia0.cloudfront.net/drone/6.jpg" },
    { image: "https://d2ehq5aws28ia0.cloudfront.net/interior/19.jpg" },
    { image: "https://d2ehq5aws28ia0.cloudfront.net/project/5.jpg" },
    { image: "https://d2ehq5aws28ia0.cloudfront.net/project/21.jpg" },
    { image: "https://d2ehq5aws28ia0.cloudfront.net/snow/8.jpg" },
    { image: "https://d2ehq5aws28ia0.cloudfront.net/project/18.jpg" },
    { image: "https://d2ehq5aws28ia0.cloudfront.net/drone/5.jpg" },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const openModal = (index) => {
    setModalIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalIndex(0);
  };

  const nextImage = () => {
    setModalIndex((prevIndex) => (prevIndex + 1) % allImages.length);
  };

  const prevImage = () => {
    setModalIndex(
      (prevIndex) => (prevIndex - 1 + allImages.length) % allImages.length
    );
  };

  return (
    <div className="min-h-screen bg-green-50 text-white overflow-hidden">
      {/* Progress Bar */}
      <div
        className="fixed top-0 left-0 h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 z-50 transition-all duration-300"
        style={{ width: `${scrollProgress}%` }}
      />

      {/* Navigation */}
      <nav className="fixed w-full z-50 bg-white bg-opacity-60 backdrop-blur-lg">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center h-30">
            <div className="flex items-center space-x-8">
              <a href='#home'><img src="https://www.indusvalleymukteshwar.com/static/media/navlogo.50214603480cefd7a8b2.png" alt="Logo" className="h-20 my-3" /></a>
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex items-center space-x-8 text-gray-800">
              <a href="#home" className="nav-link group">
                Home
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
              </a>
              <a href="#about" className="nav-link group">
                About
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
              </a>
              <a href="#features" className="nav-link group">
                Features
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
              </a>
              <a href="#gallery" className="nav-link group">
                Gallery
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
              </a>
              <a href="#floor-plans" className="nav-link group">
                Floor Plan
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
              </a>
              <button onClick={() => setShowPopup(true)} className="px-6 py-2 bg-white text-black rounded-full hover:bg-opacity-90 transition-all">
                Contact
              </button>
            </div>

            {/* Mobile Menu Button */}
            <button
              className="md:hidden text-black"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <X /> : <Menu />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div className={`md:hidden transition-all duration-300 ${isMenuOpen ? 'max-h-96' : 'max-h-0'} flex flex-col justify-center items-center overflow-hidden`}>
          <div className="px-4 py-2 space-y-4">
            <a href="#home" className="block py-2 text-center text-black " onClick={() => setIsMenuOpen(false)}>Home</a>
            <a href="#about" className="block py-2 text-center text-black " onClick={() => setIsMenuOpen(false)}>About</a>
            <a href="#features" className="block py-2 text-center text-black " onClick={() => setIsMenuOpen(false)}>Features</a>
            <a href="#gallery" className="block py-2 text-center text-black " onClick={() => setIsMenuOpen(false)}>Gallery</a>
            <a href="#contact" className="block py-2 text-center text-black " onClick={() => setIsMenuOpen(false)}>Contact</a>
            <button onClick={() => setShowPopup(true)} className="w-full px-6 py-2 bg-white text-black rounded-full hover:bg-opacity-90">
              Contact
            </button>
          </div>
        </div>
      </nav>

      <div>
        {/* Hero Carousel */}
        <section id="home" className=" scroll-m-[100px] h-screen relative">
          {carouselSlides.map((slide, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-opacity duration-1000 ${index === currentSlide ? 'opacity-100' : 'opacity-0'
                }`}
            >
              <div
                className="absolute inset-0 bg-cover bg-center"
                style={{ backgroundImage: `url(${slide.image})` }}
              >
                <div className="absolute inset-0 " />
              </div>
              <div className="relative h-full flex gap-5 flex-col bg-black/5 items-center justify-center text-center px-4">
                <div className="">
                  <div
                    className="text-7xl md:text-[120px] font-bold"
                    style={{
                      transform: animate ? 'translateY(0)' : 'translateY(100%)',
                      opacity: animate ? 1 : 0,
                      transition: 'all 1s cubic-bezier(0.4, 0, 0.2, 1)',
                      transitionDelay: '0ms',
                    }}
                  >
                    <span className="text-white ">
                      Cottage
                    </span>
                  </div>
                </div>

                {/* "starting from" text */}
                <div className="">
                  <div
                    className="text-3xl md:text-4xl text-white"
                    style={{
                      transform: animate ? 'translateY(0)' : 'translateY(100%)',
                      opacity: animate ? 1 : 0,
                      transition: 'all 1s cubic-bezier(0.4, 0, 0.2, 1)',
                      transitionDelay: '300ms',
                    }}
                  >
                    starting from
                  </div>
                </div>

                {/* Price text */}
                <div className="">
                  <div
                    className="text-6xl md:text-[140px] font-bold relative"
                    style={{
                      transform: animate ? 'translateY(0) scale(1)' : 'translateY(100%) scale(0.8)',
                      opacity: animate ? 1 : 0,
                      transition: 'all 1s cubic-bezier(0.4, 0, 0.2, 1)',
                      transitionDelay: '300ms',
                    }}
                  >
                    <span className=" text-white">
                      ₹70 Lakhs
                    </span>

                    {/* Price shine effect */}
                    <div
                      className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent skew-x-12"
                      style={{
                        transform: animate ? 'translateX(200%)' : 'translateX(-200%)',
                        transition: 'transform 1s ease-in-out',
                        transitionDelay: '500ms',
                      }}
                    />
                  </div>
                </div>
              </div>

            </div>
          ))}

          {/* Carousel Controls */}
          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 p-3 rounded-full hover:bg-opacity-75 transition-all"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 p-3 rounded-full hover:bg-opacity-75 transition-all"
          >
            <ChevronRight className="w-6 h-6" />
          </button>

          {/* Slide Indicators */}
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-3">
            {carouselSlides.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-16 h-1 rounded-full transition-all ${index === currentSlide ? 'bg-white' : 'bg-white bg-opacity-50'
                  }`}
              />
            ))}
          </div>


          {/* Scroll Indicator */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ 
              opacity: scrollPosition > 100 ? 0 : 1,
              y: scrollPosition > 100 ? 50 : 0
            }}
            transition={{ delay: 0.5, duration: 1 }}
            className="absolute bottom-16 left-1/2 -translate-x-1/2 z-20 text-white flex flex-col items-center gap-2"
          >
            <span className="text-sm tracking-wider uppercase opacity-70">Scroll</span>
            <div className="w-6 h-10 border-2 border-white/50 rounded-full p-1">
              <motion.div
                animate={{
                  y: [0, 12, 0],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
                className="w-2 h-2 bg-white rounded-full mx-auto"
              />
            </div>
          </motion.div>

        </section>



        <section id="about" className='container scroll-m-[150px] mx-auto'>
          <div className="text-center mb-5">
            <div className='flex flex-col mb-5 mt-[40px] justify-center items-center'>
              <h1 className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
                Indus valley Mukteshwar
              </h1>
              <img className='ml-[-10px]' src={bottomline} alt='' />
            </div>
            <p className="text-xl px-5 md:px-0 text-justify text-stone-600 md:w-[80%] poppins mx-auto mb-5">
              Welcome to Indus Valley Mukteshwar, a place where peace resides. This is a 10-acre private gated township in the lap of the Himalayas. We are not selling cottages; we are selling the environment that we create around you.

              This project is at the height of 8000 ft from sea level, which is why it also witnesses snowfall around winter. Around the year, the weather in Mukteshwar has been cool and pleasant, and to date, we haven’t felt the need to install fans in our cottages. Places like Nainital, Shimla, Mussoorie, Dehradun are overcrowded and hustle and bustle now; these places haven’t even recorded any snowfall in the last 6-7 years due to global warming.

              Enjoy the terrific panoramic view of Nanda Devi Himalayan glaciers from every property. We have built these cottages in an organized way that every cottage faces the Himalayas. There are 70 villas in the project, out of which we have delivered 43. Around 15 families have already permanently moved to Indus Valley.
            </p>
          </div>

          {/* Key Features Grid */}
          <div className='flex flex-col mt-[40px] mb-5 justify-center items-center'>
            <p className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
              Offerings of Indus Valley
            </p>
            <img className='ml-[-10px]' src={bottomline} alt='' />
          </div>
          <div className="flex justify-center">
            <div className="grid md:grid-cols-2 justify-center lg:grid-cols-3 mb-5">
              {features.map((feature, index) => (
                <div key={index} className=" p-6 rounded-lg transition-shadow">
                  <div className="flex items-start justify-center gap-2">
                    <img className="h-[30px]" src={feature.icon} alt="" />
                    <h3 className="text-xl font-semibold text-stone-800 mb-2 poppins">{feature.title}</h3>
                  </div>
                  <p className="text-stone-600 poppins text-center">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Unique Points Section */}
          <div className='flex flex-col mt-[40px] mb-5 justify-center items-center'>
            <p className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
              Unique points of Indus Valley
            </p>
            <img className='ml-[-10px]' src={bottomline} alt='' />
          </div>
          <div className="grid md:w-[90%] mx-auto justify-center md:grid-cols-2 lg:grid-cols-3 gap-6">
            {uniquePoints.map((point, index) => (
              <div key={index} className="flex items-center justify-center space-x-4 p-4  rounded-lg">
                <img className="h-[30px]" src={point.icon} alt="" />
                <span className="text-gray-800 text-xl font-semibold poppins">{point.text}</span>
              </div>
            ))}
          </div>

          {/* Location Section */}
          <div className=" rounded-xl mb-5">
            <div className='flex flex-col mt-[40px] mb-5 justify-center items-center'>
              <p className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
                Location
              </p>
              <img className='ml-[-10px]' src={bottomline} alt='' />
            </div>
            <p className="text-stone-600 text-center poopins">
              It is a 7-hour drive from Delhi and a 1-hour drive from Nainital, a perfect destination to invest in your dream house. The project is in Sundarkhal, which is in the main Mukteshwar region. This project is 5 minutes from the Dhanachuli market, where the residents can get all their daily essentials.
            </p>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="py-5 scroll-m-[100px] bg-green-50 mb-5">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-2">
              <h2 className="text-3xl md:text-4xl font-bold mb-4 text-green-700 yeseva">Premium Features</h2>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {[
                {
                  icon: <Home className="w-6 h-6" />,
                  title: "Luxury Villas",
                  description: "Spacious 4-5 bedroom villas with modern amenities"
                },
                {
                  icon: <Map className="w-6 h-6" />,
                  title: "Prime Location",
                  description: "Situated in the heart of Mukteshwar with panoramic views"
                },
                {
                  icon: <Users className="w-6 h-6" />,
                  title: "Exclusive Community",
                  description: "Join a community of like-minded residents"
                },
                {
                  icon: <Armchair className="w-6 h-6" />,
                  title: "Fully-Furnished Options",
                  description: "Move in ready with top-of-the-line furniture"
                },
                {
                  icon: <Home className="w-6 h-6" />,
                  title: "Spacious Layouts",
                  description: "Designed for comfort and flexibility"
                },
                {
                  icon: <Mountain className="w-6 h-6" />,
                  title: "Mountains View",
                  description: "Stunning views of the majestic mountains right from your villa"
                }
              ].map((feature, index) => (
                <div
                  key={index}
                  className="group flex flex-col justify-center items-center p-6 rounded-2xl bg-green-50 transition-all duration-500 transform hover:-translate-y-2"
                >
                  <div className="mb-3 p-3 rounded-full text-center bg-green-500 bg-opacity-20 inline-block text-green-400">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-center text-gray-700">{feature.title}</h3>
                  <p className="text-gray-500 text-sm text-center">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Gallery Section */}
        <section id="gallery" className="py-2 scroll-m-[100px] bg-green-50 mb-5">
          <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-green-700 yeseva">Our Gallery</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {allImages.map((e, i) => (
                <div
                  key={i}
                  className="group relative overflow-hidden rounded-xl aspect-video cursor-pointer"
                  onClick={() => openModal(i)}
                >
                  <img
                    src={e.image}
                    alt={`Gallery ${i + 1}`}
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex items-end p-4"></div>
                </div>
              ))}
            </div>
          </div>
          {/* Modal Popup */}
          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 h-full flex justify-center items-center z-50">
              <div className="relative bg-white/40 w-full h-full flex justify-center items-center p-5">
                <button
                  onClick={closeModal}
                  className="absolute top-2 right-2 text-white bg-red-500 p-2 h-12 w-12 rounded-full"
                >
                  X
                </button>
                <div className="flex justify-center items-center">
                  <button
                    onClick={prevImage}
                    className="text-white absolute left-2 bg-black p-3 rounded-full shadow-lg h-12 w-12"
                  >
                    &lt;
                  </button>
                  <img
                    src={allImages[modalIndex].image}
                    alt={`Modal Image ${modalIndex + 1}`}
                    className="w-full md:max-w-[1000px] h-auto rounded-xl"
                  />
                  <button
                    onClick={nextImage}
                    className="text-white absolute right-2 bg-black p-3 rounded-full shadow-lg h-12 w-12"
                  >
                    &gt;
                  </button>
                </div>

              </div>
            </div>
          )}
        </section>

        {/* Floor Section */}
        <section id="floor-plans" className="py-3 scroll-m-[100px] bg-green-50 mb-5">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-semibold mb-8 text-center text-green-700">Our Floor Plans</h2>

            {/* Floor Plan Navigation Bar */}
            <div className="grid grid-cols-2 justify-center sm:grid-cols-3 md:grid-cols-4 gap-5 mb-6">
              <button
                onClick={() => setFloorPlan("Studios")}
                className={`px-2 py-2 rounded-full text-lg font-medium ${floorPlan === "Studios" ? "bg-green-700 text-white" : "bg-transparent text-gray-800 border-2 border-gray-700 hover:bg-gray-100"}`}
              >
                Studios
              </button>
              <button
                onClick={() => setFloorPlan("4bhk")}
                className={`px-2 py-2 rounded-full text-lg font-medium ${floorPlan === "4bhk" ? "bg-green-700 text-white" : "bg-transparent text-gray-800 border-2 border-gray-700 hover:bg-gray-100"}`}
              >
                4 BHK
              </button>
              <button
                onClick={() => setFloorPlan("2bhk")}
                className={`px-2 py-2 rounded-full text-lg font-medium ${floorPlan === "2bhk" ? "bg-green-700 text-white" : "bg-transparent text-gray-800 border-2 border-gray-700 hover:bg-gray-100"}`}
              >
                2 BHK
              </button>
              <button
                onClick={() => setFloorPlan("1bhk")}
                className={`px-2 py-2 rounded-full text-lg font-medium ${floorPlan === "1bhk" ? "bg-green-700 text-white" : "bg-transparent text-gray-800 border-2 border-gray-700 hover:bg-gray-100"}`}
              >
                1 BHK
              </button>
            </div>

            {/* Floor Plan Content */}
            <div className="flex justify-center">
              <div className="max-w-3xl w-full">
                {floorPlan === "Studios" && (
                  <div className="text-center">
                    <img
                      src="https://d2ehq5aws28ia0.cloudfront.net/floorPlan/floor69.webp"
                      alt="Studio Floor Plan"
                      className="w-full h-auto rounded-lg shadow-md"
                    />
                    <p className="text-gray-700 mt-4 text-lg">Modern studio apartment with efficient space utilization, offering a cozy and functional living area ideal for singles or couples.</p>
                  </div>
                )}
                {floorPlan === "4bhk" && (
                  <div className="text-center">
                    <img
                      src="https://d2ehq5aws28ia0.cloudfront.net/floorPlan/4bhkfloor1.jpg"
                      alt="4 BHK Floor Plan"
                      className="w-full h-auto rounded-lg shadow-md"
                    />
                    <p className="text-gray-700 mt-4 text-lg">Spacious 4 BHK floor plan with modern amenities and ample space for your family.</p>
                  </div>
                )}

                {floorPlan === "2bhk" && (
                  <div className="text-center">
                    <img
                      src="https://d2ehq5aws28ia0.cloudfront.net/floorPlan/plot01b.png"
                      alt="2 BHK Floor Plan"
                      className="w-full h-auto rounded-lg shadow-md"
                    />
                    <p className="text-gray-700 mt-4 text-lg">Perfect 2 BHK for a small family or a cozy home setup.</p>
                  </div>
                )}

                {floorPlan === "1bhk" && (
                  <div className="text-center">
                    <img
                      src="https://d2ehq5aws28ia0.cloudfront.net/floorPlan/plot902.webp"
                      alt="1 BHK Floor Plan"
                      className="w-full h-auto rounded-lg shadow-md"
                    />
                    <p className="text-gray-700 mt-4 text-lg">Ideal 1 BHK for a single person or a couple looking for a compact living space.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="py-5 scroll-m-[100px] mb-5 bg-gradient-to-b from-green-50 to-green-50">
          <div className="max-w-4xl mx-auto px-4 text-center">
            <h2 className="text-4xl md:text-5xl font-bold mb-8 text-green-700 yeseva">Get in Touch</h2>
            <p className="text-xl text-gray-600 mb-12">Schedule a site visit or request more information</p>

            <form className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="w-full px-6 py-4 bg-green-50 border-2 border-green-600 text-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="tel"
                  placeholder="Your Number"
                  value={phone}
                  maxLength="10"
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full px-6 py-4 bg-green-50 border-2 border-green-600 text-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <button onClick={(e) => { handleSubmit(e) }} className="px-8 py-4 bg-green-700 rounded-lg hover:opacity-90 transition-all transform hover:scale-105">
                Send Message
              </button>
            </form>
          </div>
        </section>

        {/* Footer */}
        <Footer />

        {/* Welcome Popup */}
        {showPopup && (
          <Form setShowPopup={setShowPopup} position="fixed" handleSubmit={handleSubmit} name={name} setName={setName} phone={phone} setPhone={setPhone} />
        )}
      </div>
    </div>
  );
};



export default CreativeLandingPage;
