import React, { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight, Pause, Play, Shuffle, Maximize2, Minimize2, Grid } from 'lucide-react';
import { Helmet } from "react-helmet";
import bottomline from "../newComponents/asset/bottomline.png"

const Gallery3 = ({imgs}) => {
  const [loading, setLoading] = useState(true)

  const galleryimages = {
    exterior: [
      { id: 1, url: "https://d2ehq5aws28ia0.cloudfront.net/drone/1.jpg", title:"drone", featured: true },
      { id: 2, url: "https://d2ehq5aws28ia0.cloudfront.net/drone/2.jpg", title:"drone", featured: true },
      { id: 3, url: "https://d2ehq5aws28ia0.cloudfront.net/drone/3.jpg", title:"drone", featured: true },
      { id: 4, url: "https://d2ehq5aws28ia0.cloudfront.net/drone/4.jpg", title:"drone", featured: true },
      { id: 5, url: "https://d2ehq5aws28ia0.cloudfront.net/drone/5.jpg", title:"drone", featured: true },
      { id: 6, url: "https://d2ehq5aws28ia0.cloudfront.net/drone/6.jpg", title:"drone", featured: false },
      { id: 7, url: "https://d2ehq5aws28ia0.cloudfront.net/drone/7.jpg", title:"drone", featured: false },
      { id: 8, url: "https://d2ehq5aws28ia0.cloudfront.net/drone/8.jpg", title:"drone", featured: false },
      { id: "d9", url: "https://d2ehq5aws28ia0.cloudfront.net/drone/9.jpeg", title:"drone", featured: false },
      { id: "d10", url: "https://d2ehq5aws28ia0.cloudfront.net/drone/10.jpg", title:"drone", featured: false },
      { id: "d11", url: "https://d2ehq5aws28ia0.cloudfront.net/drone/11.jpg", title:"drone", featured: false },
      { id: "d12", url: "https://d2ehq5aws28ia0.cloudfront.net/drone/12.jpg", title:"drone", featured: false },
      { id: "d13", url: "https://d2ehq5aws28ia0.cloudfront.net/drone/13.jpg", title:"drone", featured: false },
      { id: "d14", url: "https://d2ehq5aws28ia0.cloudfront.net/drone/14.jpg", title:"drone", featured: false },
      { id: "d15", url: "https://d2ehq5aws28ia0.cloudfront.net/drone/15.jpg", title:"drone", featured: false },
      { id: 9, url: "https://d2ehq5aws28ia0.cloudfront.net/himalayan/1.jpg", title:"himalayan", featured: true },
      { id: 10, url: "https://d2ehq5aws28ia0.cloudfront.net/himalayan/2.jpg", title:"himalayan", featured: true },
      { id: 11, url: "https://d2ehq5aws28ia0.cloudfront.net/himalayan/3.jpg", title:"himalayan", featured: true },
      { id: 12, url: "https://d2ehq5aws28ia0.cloudfront.net/himalayan/4.jpg", title:"himalayan", featured: true },
      { id: 13, url: "https://d2ehq5aws28ia0.cloudfront.net/himalayan/5.jpg", title:"himalayan", featured: true },
      { id: 14, url: "https://d2ehq5aws28ia0.cloudfront.net/himalayan/6.jpg", title:"himalayan", featured: false },
      { id: 15, url: "https://d2ehq5aws28ia0.cloudfront.net/himalayan/7.jpg", title:"himalayan", featured: false },
      { id: 49, url: "https://d2ehq5aws28ia0.cloudfront.net/himalayan/8.jpg", title:"himalayan", featured: false },
      { id: 50, url: "https://d2ehq5aws28ia0.cloudfront.net/himalayan/9.jpg", title:"himalayan", featured: false },
      { id: 51, url: "https://d2ehq5aws28ia0.cloudfront.net/himalayan/10.jpg", title:"himalayan", featured: false },
      { id: 52, url: "https://d2ehq5aws28ia0.cloudfront.net/himalayan/11.jpg", title:"himalayan", featured: false },
      { id: 16, url: "https://d2ehq5aws28ia0.cloudfront.net/project/8.jpg", title:"project", featured: false },
      { id: 17, url: "https://d2ehq5aws28ia0.cloudfront.net/project/1.jpg", title:"project", featured: true },
      { id: 18, url: "https://d2ehq5aws28ia0.cloudfront.net/project/2.jpg", title:"project", featured: true },
      { id: 19, url: "https://d2ehq5aws28ia0.cloudfront.net/project/3.jpg", title:"project", featured: true },
      { id: 20, url: "https://d2ehq5aws28ia0.cloudfront.net/project/4.jpg", title:"project", featured: true },
      { id: 21, url: "https://d2ehq5aws28ia0.cloudfront.net/project/5.jpg", title:"project", featured: true },
      { id: 22, url: "https://d2ehq5aws28ia0.cloudfront.net/project/6.jpg", title:"project", featured: false },
      { id: 23, url: "https://d2ehq5aws28ia0.cloudfront.net/project/7.jpg", title:"project", featured: false },
      { id: 24, url: "https://d2ehq5aws28ia0.cloudfront.net/project/8.jpg", title:"project", featured: false },
      { id: 25, url: "https://d2ehq5aws28ia0.cloudfront.net/project/9.jpg", title:"project", featured: false },
      { id: 26, url: "https://d2ehq5aws28ia0.cloudfront.net/project/10.jpg", title:"project", featured: false },
      { id: 27, url: "https://d2ehq5aws28ia0.cloudfront.net/project/11.jpg", title:"project", featured: false },
      { id: 28, url: "https://d2ehq5aws28ia0.cloudfront.net/project/12.jpg", title:"project", featured: false },
      { id: 29, url: "https://d2ehq5aws28ia0.cloudfront.net/project/13.jpg", title:"project", featured: false },
      { id: 30, url: "https://d2ehq5aws28ia0.cloudfront.net/project/14.jpg", title:"project", featured: false },
      { id: 31, url: "https://d2ehq5aws28ia0.cloudfront.net/project/15.jpg", title:"project", featured: false },
      { id: 32, url: "https://d2ehq5aws28ia0.cloudfront.net/project/16.jpg", title:"project", featured: false },
      { id: 33, url: "https://d2ehq5aws28ia0.cloudfront.net/project/17.jpg", title:"project", featured: false },
      { id: 34, url: "https://d2ehq5aws28ia0.cloudfront.net/project/18.jpg", title:"project", featured: false },
      { id: 35, url: "https://d2ehq5aws28ia0.cloudfront.net/project/19.jpg", title:"project", featured: false },
      { id: 36, url: "https://d2ehq5aws28ia0.cloudfront.net/project/20.jpg", title:"project", featured: false },
      { id: 37, url: "https://d2ehq5aws28ia0.cloudfront.net/project/21.jpg", title:"project", featured: false },
      { id: 38, url: "https://d2ehq5aws28ia0.cloudfront.net/project/22.jpg", title:"project", featured: false },
      { id: 39, url: "https://d2ehq5aws28ia0.cloudfront.net/project/23.jpg", title:"project", featured: false },
      { id: 40, url: "https://d2ehq5aws28ia0.cloudfront.net/snow/1.jpg", title:"snow", featured: false },
      { id: 41, url: "https://d2ehq5aws28ia0.cloudfront.net/snow/2.jpg", title:"snow", featured: false },
      { id: 42, url: "https://d2ehq5aws28ia0.cloudfront.net/snow/3.jpg", title:"snow", featured: false },
      { id: 43, url: "https://d2ehq5aws28ia0.cloudfront.net/snow/4.jpg", title:"snow", featured: false },
      { id: 44, url: "https://d2ehq5aws28ia0.cloudfront.net/snow/5.jpg", title:"snow", featured: false },
      { id: 45, url: "https://d2ehq5aws28ia0.cloudfront.net/snow/6.jpg", title:"snow", featured: false },
      { id: 46, url: "https://d2ehq5aws28ia0.cloudfront.net/snow/7.jpg", title:"snow", featured: false },
      { id: 47, url: "https://d2ehq5aws28ia0.cloudfront.net/snow/8.jpg", title:"snow", featured: false },
      { id: 48, url: "https://d2ehq5aws28ia0.cloudfront.net/snow/9.jpg", title:"snow", featured: false },
    ],
    interior: [
      { "id": "i1", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/1.jpg", "title": "interior", "featured": true },
      { "id": "i2", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/2.jpg", "title": "interior", "featured": true },
      { "id": "i3", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/3.jpg", "title": "interior", "featured": true },
      { "id": "i4", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/4.jpg", "title": "interior", "featured": true },
      { "id": "i5", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/5.jpg", "title": "interior", "featured": true },
      { "id": "i6", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/6.jpg", "title": "interior", "featured": true },
      { "id": "i7", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/7.jpg", "title": "interior", "featured": true },
      { "id": "i8", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/8.jpg", "title": "interior", "featured": true },
      { "id": "i9", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/9.jpg", "title": "interior", "featured": true },
      { "id": "i10", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/10.jpg", "title": "interior", "featured": true },
      { "id": "i11", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/11.jpg", "title": "interior", "featured": true },
      { "id": "i13", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/13.jpg", "title": "interior", "featured": true },
      { "id": "i14", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/14.jpg", "title": "interior", "featured": true },
      { "id": "i15", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/15.jpg", "title": "interior", "featured": true },
      { "id": "i16", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/16.jpg", "title": "interior", "featured": true },
      { "id": "i17", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/17.jpg", "title": "interior", "featured": true },
      { "id": "i18", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/18.jpg", "title": "interior", "featured": true },
      { "id": "i19", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/19.jpg", "title": "interior", "featured": true },
      { "id": "i20", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/20.jpg", "title": "interior", "featured": true },
      { "id": "i21", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/21.jpg", "title": "interior", "featured": true },
      { "id": "i23", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/55.jpg", "title": "interior", "featured": true },
      { "id": "i24", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/24.jpg", "title": "interior", "featured": true },
      { "id": "i25", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/25.jpg", "title": "interior", "featured": true },
      { "id": "i26", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/26.jpg", "title": "interior", "featured": true },
      { "id": "i27", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/27.jpg", "title": "interior", "featured": true },
      { "id": "i28", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/28.jpg", "title": "interior", "featured": true },
      { "id": "i29", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/29.jpg", "title": "interior", "featured": true },
      { "id": "i34", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/34.jpg", "title": "interior", "featured": true },
      { "id": "i35", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/35.jpg", "title": "interior", "featured": true },
      { "id": "i36", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/36.jpg", "title": "interior", "featured": true },
      { "id": "i37", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/37.jpg", "title": "interior", "featured": true },
      { "id": "i39", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/39.jpg", "title": "interior", "featured": true },
      { "id": "i56", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/56.jpg", "title": "interior", "featured": true },
    ],
    restaurant :[
      { "id": "r1", "url": "https://d2ehq5aws28ia0.cloudfront.net/restaurant/1.jpg", "title": "restaurant", "featured": true},
      { "id": "r2", "url": "https://d2ehq5aws28ia0.cloudfront.net/restaurant/2.jpg", "title": "restaurant", "featured": true },
      { "id": "r3", "url": "https://d2ehq5aws28ia0.cloudfront.net/restaurant/3.jpg", "title": "restaurant", "featured": true },
      { "id": "r4", "url": "https://d2ehq5aws28ia0.cloudfront.net/restaurant/4.jpg", "title": "restaurant", "featured": true },
      { "id": "r5", "url": "https://d2ehq5aws28ia0.cloudfront.net/restaurant/5.jpg", "title": "restaurant", "featured": true },
    ],
    carousel:[
      { "id": "c15", "url": "https://d2ehq5aws28ia0.cloudfront.net/ph.png", title:"Gated Township In Mukteshwar"},
      { "id": "c14", "url": "https://d2ehq5aws28ia0.cloudfront.net/project/5.jpg", title:"Gated Society In Mukteshwar"},
      { "id": "c13", "url": "https://d2ehq5aws28ia0.cloudfront.net/snow/8.jpg", title:"Snowfall in Mukteshwar, Uttrakhand"},
      { "id": "c12", "url": "https://d2ehq5aws28ia0.cloudfront.net/drone/4.jpg", title:"Cottages in Mukteshwar"},
      { "id": "c11", "url": "https://d2ehq5aws28ia0.cloudfront.net/himalayan/6.jpg", title:"Mountains"},
      { "id": "c10", "url": "https://d2ehq5aws28ia0.cloudfront.net/project/11.jpg", title:"Villas In Mukteshwar"},
      { "id": "c5", "url": "https://d2ehq5aws28ia0.cloudfront.net/snow/7.jpg", title:""},
      { "id": "c6", "url": "https://d2ehq5aws28ia0.cloudfront.net/drone/6.jpg", title:" Township In Mukteshwar"},
      { "id": "c2", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/55.jpg", title:"Cottage"},
      { "id": "c3", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/27.jpg", title:""},
      { "id": "c4", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/19.jpg", title:""},
      { "id": "c8", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/39.jpg", title:""},
      { "id": "c9", "url": "https://d2ehq5aws28ia0.cloudfront.net/interior/1.jpg",title:"Mountain View from Mukteshwar"},
      { "id": "c1", "url": "https://d2ehq5aws28ia0.cloudfront.net/restaurant/1.jpg", title:"Restaurant in Mukteshwar"},
    ]    
  }

  const [allImages] = useState(imgs ? imgs : galleryimages);
  
  const [activeCategory, setActiveCategory] = useState('all');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showThumbnails, setShowThumbnails] = useState(true);
  const [lightboxAutoplay, setLightboxAutoplay] = useState(false);

  const featuredImages = [
    ...allImages.carousel
  ];

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(false)
    },1000)
  }, [])

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  useEffect(() => {
    let images = [];
    if (activeCategory === 'all') {
      images = [...allImages.exterior, ...allImages.interior];
    } else {
      images = [...allImages[activeCategory]];
    }
    setDisplayedImages(shuffleArray(images));
  }, [activeCategory, allImages]);

  const handleShuffle = () => {
    setDisplayedImages(prev => shuffleArray([...prev]));
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  // Modified keyboard handler for lightbox only
  const handleKeyPress = useCallback((event) => {
    if (lightboxOpen) {
      switch (event.key) {
        case 'ArrowLeft':
          setLightboxIndex(prev => 
            prev === 0 ? displayedImages.length - 1 : prev - 1
          );
          break;
        case 'ArrowRight':
          setLightboxIndex(prev => 
            prev === displayedImages.length - 1 ? 0 : prev + 1
          );
          break;
        case 'Escape':
          setLightboxOpen(false);
          break;
        case 'f':
          toggleFullscreen();
          break;
        case ' ':
          event.preventDefault();
          setLightboxAutoplay(prev => !prev);
          break;
        default:
          break;
      }
    }
  }, [lightboxOpen, displayedImages.length]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  useEffect(() => {
    let interval;
    if (lightboxOpen && lightboxAutoplay) {
      interval = setInterval(() => {
        setLightboxIndex(prev => 
          prev === displayedImages.length - 1 ? 0 : prev + 1
        );
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [lightboxOpen, lightboxAutoplay, displayedImages.length]);

  useEffect(() => {
    let interval;
    if (isPlaying && !isHovered && !lightboxOpen) {
      interval = setInterval(() => {
        setCurrentSlide(prev => 
          prev === featuredImages.length - 1 ? 0 : prev + 1
        );
      }, 1500);
    }
    return () => clearInterval(interval);
  }, [isPlaying, isHovered, featuredImages.length, lightboxOpen]);

  const Lightbox = () => {
    if (!lightboxOpen) return null;

    return (
      <div className="fixed top-0 left-0 inset-0 bg-black/90 z-50 flex flex-col" style={{marginTop: 0}}> 
      
        <div className="absolute top-4 right-4 flex gap-2 z-10">
          <button
            onClick={() => setShowThumbnails(prev => !prev)}
            className="p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
          >
            <Grid size={24} />
          </button>
          <button
            onClick={toggleFullscreen}
            className="p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
          >
            {isFullscreen ? <Minimize2 size={24} /> : <Maximize2 size={24} />}
          </button>
          <button
            onClick={() => setLightboxAutoplay(prev => !prev)}
            className="p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
          >
            {lightboxAutoplay ? <Pause size={24} /> : <Play size={24} />}
          </button>
          <button
            onClick={() => setLightboxOpen(false)}
            className="p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
          >
            ✕
          </button>
        </div>

        <div className="flex-1 flex items-center justify-center p-4">
          <img
            src={displayedImages[lightboxIndex].url}
            alt={displayedImages[lightboxIndex].title}
            className="h-full top-0 absolute  object-contain"
          />
        </div>

        <button
          onClick={() => setLightboxIndex(prev => 
            prev === 0 ? displayedImages.length - 1 : prev - 1
          )}
          className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-transparent w-[10%] h-full text-white transition-colors"
        >
          <ChevronLeft size={24} />
        </button>
        <button
          onClick={() => setLightboxIndex(prev => 
            prev === displayedImages.length - 1 ? 0 : prev + 1
          )}
          className="absolute right-4 flex flex-col justify-center items-end top-1/2 -translate-y-1/2 p-2 rounded-full bg-transparent w-[10%] h-full text-white transition-colors"
        >
          <ChevronRight size={24} />
        </button>

        {showThumbnails && (
          <div className="absolute bottom-0 inset-x-0 bg-black/50 p-4">
            <div className="flex gap-2 overflow-x-auto">
              {displayedImages.map((img, index) => (
                <button
                  key={img.id}
                  onClick={() => setLightboxIndex(index)}
                  className={`flex-shrink-0 ${
                    lightboxIndex === index ? 'ring-2 ring-blue-500' : ''
                  }`}
                >
                  <img
                    src={img.url}
                    alt={img.title}
                    className="h-16 w-16 object-cover"
                  />
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
  <>
  <div className="relative h-[130px] w-full">
  <Helmet>
        <title>Gallery of Stunning Cottages in Mukteshwar</title>
        <meta
          name="description"
          content="Browse the gallery of stunning cottages in Mukteshwar. Indus Valley offers premium property in Uttarakhand with picturesque views."
        />
      </Helmet>

      </div>
    <div className="max-w-6xl mx-auto p-6 space-y-12">
      <div className="space-y-4">
      <div className='flex flex-col mb-5 mt-[40px] justify-center items-center'>
        <p className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
          Gallery
        </p>
        <img className='ml-[-10px]' src={bottomline} alt=''/>
      </div>
      {
      loading ? 
      <>
      <div className="h-[300px] md:h-[400px] lg:h-[500px] w-[90%] md:w-[85%] lg:w-[80%] mx-auto">
  <div className="relative h-full overflow-hidden rounded-xl bg-gray-300 animate-pulse">
    {/* Image Skeleton */}
    <div className="aspect-[16/9] bg-gray-300" />

    {/* Left Arrow Skeleton */}
    <div className="absolute left-4 top-1/2 -translate-y-1/2 bg-gray-400 p-2 rounded-full h-8 w-8 md:h-10 md:w-10" />

    {/* Right Arrow Skeleton */}
    <div className="absolute right-4 top-1/2 -translate-y-1/2 bg-gray-400 p-2 rounded-full h-8 w-8 md:h-10 md:w-10" />

    {/* Play/Pause Button Skeleton */}
    <div className="absolute bottom-4 right-4 bg-gray-400 p-2 rounded-full h-8 w-8 md:h-10 md:w-10" />
  </div>
</div>
      </> :<div
          className="relative overflow-hidden rounded-xl"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="aspect-[16/9] relative">
            <img
              src={featuredImages[currentSlide].url}
              alt={featuredImages[currentSlide].title}
              className="w-full h-full object-cover"
            />
          </div>

          <button
            onClick={() => setCurrentSlide(prev => prev === 0 ? featuredImages.length - 1 : prev - 1)}
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/50 p-2 rounded-full text-white hover:bg-black/70 transition-colors"
          >
            <ChevronLeft size={24} />
          </button>
          <button
            onClick={() => setCurrentSlide(prev => prev === featuredImages.length - 1 ? 0 : prev + 1)}
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/50 p-2 rounded-full text-white hover:bg-black/70 transition-colors"
          >
            <ChevronRight size={24} />
          </button>

          <button
            onClick={() => setIsPlaying(!isPlaying)}
            className="absolute bottom-4 right-4 bg-black/50 p-2 rounded-full text-white hover:bg-black/70 transition-colors"
          >
            {isPlaying ? <Pause size={24} /> : <Play size={24} />}
          </button>

        </div>
}
      </div>

      <div className="space-y-6">
        <div>
          <h2 className="text-2xl font-bold mb-4">All Images</h2>
          
          <div className="flex flex-wrap justify-center gap-4 mb-8">
            <div className="flex gap-4">
              <button
                onClick={() => setActiveCategory('all')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeCategory === 'all'
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
              >
                All
              </button>
              <button
                onClick={() => setActiveCategory('exterior')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeCategory === 'exterior'
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
              >
                Exterior
              </button>
              <button
                onClick={() => setActiveCategory('interior')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeCategory === 'interior'
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
              >
                Interior
              </button>
              <button
                onClick={() => setActiveCategory('restaurant')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeCategory === 'restaurant'
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
              >
                Restaurant
              </button>
            </div>
            <button
              onClick={handleShuffle}
              className="flex items-center gap-2 px-4 py-2 rounded-lg bg-purple-500 text-white hover:bg-purple-600 transition-colors"
            >
              <Shuffle size={16} />
              Shuffle
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4">
        {displayedImages.map((image, index) => (
              <button
                key={image.id}
                onClick={() => {
                  setLightboxIndex(index);
                  setLightboxOpen(true);
                }}
                className="relative overflow-hidden aspect-video rounded-lg group focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                <img
                  src={image.url}
                  alt={image.title}
                  className="w-full h-full object-cover group-hover:opacity-90 transition-opacity"
                />
                {image.featured && (
                  <div className="absolute top-2 right-2 bg-green-500 text-white text-xs px-2 py-1 rounded">
                    Featured
                  </div>
                )}
                <div className="absolute inset-0 bg-black/0 group-hover:bg-black/20 transition-colors">
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                    <Maximize2 className="text-white" size={24} />
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>

        <Lightbox />
      </div>
      </>
    );
};

export default Gallery3;
